/* @import url('https://fonts.googleapis.com/css?family=Klee+One:regular,bold,italic&subset=latin,latin-ext'); */
/* @import url('https://fonts.googleapis.com/css?family=Josefin+Sans'); */
@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap');

body {
  font-family: 'Klee One', cursive;
  background-color: #eee!important;
}

header {
  padding: 0 !important;
}

.layout {
  min-height: 100vh;
}
.ant-menu, .ant-menu-sub, .ant-menu-inline {
  background-color: #782A5E!important;
}

.bg-color-dark {
  background-color: #9E5288;
}

.bg-color-medium {
  background-color: #782A5E;
}

.bg-color-light {
  background-color: #5F3159;
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

/* Home */
.slide {
  height: calc(100vh - 64px);
  color: #fff;
  text-align: center;
  background: #5F3159;
  background-position: center;
  background-size: cover;
}

.slide-1-logo {
  position: absolute;
  max-height: 80vh;
  max-width: 100vw;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  bottom: 50px;
}

@media screen and (min-width:450px) {
  .slide-1-logo {
    position: absolute;
    max-height: 80vh;
    max-width: 50vw;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    bottom: 50px;
  }
}  

.angebote-container {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
}

/* .angebote-container div {
  flex: 2 0 21%;
  height: 50px;
  color: #5F3159;
  font-size: calc((75vw - 4.5rem) / 7);
  background-color: #fff;
  border-radius: 10px;
  width: 200px;
} */


ul.cloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 2.75rem;
  width: 70vw;
}

ul.cloud a {
  /*   
  Not supported by any browser at the moment :(
  --size: attr(data-weight number); 
  */
  --size: 4;
  --color: #dab8da;
  color: var(--color);
  font-size: calc(var(--size) * 0.25rem + 0.5rem);
  display: block;
  padding: 0.125rem 0.25rem;
  position: relative;
  text-decoration: none;
  /* 
  For different tones of a single color
  opacity: calc((15 - (9 - var(--size))) / 15); 
  */
}

ul.cloud a[data-weight="1"] {
  --size: 1;
}

ul.cloud a[data-weight="2"] {
  --size: 2;
}

ul.cloud a[data-weight="3"] {
  --size: 3;
}

ul.cloud a[data-weight="4"] {
  --size: 4;
}

ul.cloud a[data-weight="5"] {
  --size: 6;
}

ul.cloud a[data-weight="6"] {
  --size: 8;
}

ul.cloud a[data-weight="7"] {
  --size: 10;
}

ul.cloud a[data-weight="8"] {
  --size: 13;
}

ul.cloud a[data-weight="9"] {
  --size: 16;
}

ul[data-show-value] a::after {
  content: " ("attr(data-weight) ")";
  font-size: 1rem;
}

ul.cloud li:nth-child(2n+1) a {
  --color: #fff;
}

ul.cloud li:nth-child(3n+1) a {
  --color: #eee;
}

ul.cloud li:nth-child(4n+1) a {
  --color: #ccc;
}

ul.cloud a:focus {
  outline: 1px dashed;
}

ul.cloud a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background: var(--color);
  transform: translate(-50%, 0);
  opacity: 0.15;
  transition: width 0.25s;
}

ul.cloud a:focus::before,
ul.cloud a:hover::before {
  width: 100%;
}

@media (prefers-reduced-motion) {
  ul.cloud * {
    transition: none !important;
  }
}














.main-content {
  min-height: 280px;
  /* padding: 24px; */
  background: #fff;
}

.row-container {
  display: flex;
}

@media screen and (max-width:990px) {
  .row-container {
    flex-wrap: wrap;
  }

  .item:first-child {
    flex-basis: 100%;
  }
}

.content-container {
  padding: 40px;
}

.row-container .fullimg {
  width: auto;
  max-width: 100vw;
  height: 100%;
}

.about-textbox {
  padding: 25px;
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.header {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, .85);
  line-height: 64px;
  background: #5F3159;
}

.ant-menu-horizontal {
  line-height: 64px!important;
}

.logo {
  background-color: white;
}

.ant-layout-header, .menu {
  background-color: #5F3159 !important;
}



.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.angebote-bild-desktop,.angebote-bild-mobile {
  max-width: 50vw;
  max-height: 60vh;
  border: 1px solid #000;
  border-radius: 5px;
  -webkit-box-shadow: inset 0px 0px 15px 0px #000000; 
  box-shadow: inset 0px 0px 15px 0px #000000;
  margin: 10px 40px 40px 40px;
  float: right;
}

.angebote-bild-mobile {
  width: 80vw;
  max-width: 100%;
  max-height: 60vh;
  border-radius: 0;
  margin: 0;
  margin-top: 10px;
  float: unset;
}